const To_home = "/"
const To_About = "/About"
const To_contact ="/Contact"
const To_Login = "/Login"
const To_Register = "/Register"
const To_cart = "/Cart"
const To_Checkout = "/Checkout"
const To_Order_History = "/orders"
const To_Product_List = "/ProductList"
const To_Product_Type = "/:type"
const To_Product_Details = "/ProductDetail"
const To_Categories = "/Categories"
const To_policy = "/policy"

export {To_home,To_About,To_contact,To_Login,To_Register,To_cart,To_Checkout,To_Order_History,To_Product_List,To_Product_Details,To_Categories,To_Product_Type,To_policy}
