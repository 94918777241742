import React from "react";
import { addQuantity, selectCart, removeQuantity, deleteQuantity } from '../redux/cartSlice'
import { useDispatch, useSelector } from "react-redux";
import { imageUrl } from "../controller/WebController";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { To_Checkout, To_home, To_Login } from "../helpers/Constants";

const Cart = () => {
    const cart = useSelector(selectCart)
    const dispatch = useDispatch()
    const UserCheck = localStorage.getItem("token")
    const addQuantityonly = (productData) => {
        const isFound = cart.some(element => {
            if (productData.clothesType === "Unstiched") {
                if (element.id === productData.id && element.color === productData.color && element.clothesType === productData.clothesType) {
                    return true;
                }
                return false;
            } else {
                if (element.id === productData.id && element.color === productData.color && element.clothesType === productData.clothesType && element.size === productData.size) {
                    return true;
                }
                return false;
            }
        });
        if (isFound) {
            if (productData.clothesType === "Unstiched") {
                const singleData = {
                    id: productData.id,
                    color: productData.color,
                    clothesType: productData.clothesType,
                    cartQuantity: 1
                }
                dispatch(addQuantity(singleData))
            } else {
                const singleData = {
                    id: productData.id,
                    color: productData.color,
                    clothesType: productData.clothesType,
                    size: productData.size,
                    cartQuantity: 1
                }
                dispatch(addQuantity(singleData))
            }
        }
        NotificationManager.success('Success message', 'Product Quantity Add Successfully');

    }
    const removeQuantityonly = (productData) => {
        const isFound = cart.some(element => {
            if (productData.clothesType === "Unstiched") {
                if (element.id === productData.id && element.color === productData.color && element.clothesType === productData.clothesType && element.cartQuantity > 1) {
                    return true;
                }
                return false;
            } else {
                if (element.id === productData.id && element.color === productData.color && element.clothesType === productData.clothesType && element.size === productData.size && element.cartQuantity > 1) {
                    return true;
                }
                return false;
            }
        });
        if (isFound) {
            if (productData.clothesType === "Unstiched") {
                const singleData = {
                    id: productData.id,
                    color: productData.color,
                    clothesType: productData.clothesType,
                    cartQuantity: 1
                }
                dispatch(removeQuantity(singleData))
            } else {
                const singleData = {
                    id: productData.id,
                    color: productData.color,
                    clothesType: productData.clothesType,
                    size: productData.size,
                    cartQuantity: 1
                }
                dispatch(removeQuantity(singleData))
            }

            NotificationManager.success('Success message', 'Product Quantity Remove Successfully');
        } else {
            if (productData.clothesType === "Unstiched") {
                const singleData = {
                    id: productData.id,
                    color: productData.color,
                    clothesType: productData.clothesType,
                }
                dispatch(deleteQuantity(singleData))
            } else {
                const singleData = {
                    id: productData.id,
                    color: productData.color,
                    clothesType: productData.clothesType,
                    size: productData.size,
                }
                dispatch(deleteQuantity(singleData))
            }
            NotificationManager.success('Success message', 'Product Remove Successfully');
        }
    }
    const deleteQuantityonly = (productData) => {

        if (productData.clothesType === "Unstiched") {
            const singleData = {
                id: productData.id,
                color: productData.color,
                clothesType: productData.clothesType,
            }
            dispatch(deleteQuantity(singleData))
        } else {
            const singleData = {
                id: productData.id,
                color: productData.color,
                clothesType: productData.clothesType,
                size: productData.size,
            }
            dispatch(deleteQuantity(singleData))
        }
        NotificationManager.success('Success message', 'Product Remove Successfully');
    }
    const sum = cart.reduce((accumulator, object) => {
        return accumulator + object.cartQuantity * object.Price;
    }, 0);

    const list = cart.map((data, index) => {
        console.log(cart)
        return (
            <tr>
                <th scope="row">{index + 1}</th>
                <td><img src={`${data.Thumbnail}`} width={"60px"} /></td>
                <td>{data.Name}</td>
                <td>
                    Color: <span style={{ backgroundColor: data.color, borderRadius: "70px", padding: '5px 14px', marginLeft: "5px" }} ></span> &nbsp;
                    {data.clothesType === "Stiched" ?
                        <>Size: <strong>{data.size}</strong> &nbsp;</>
                        :
                        <>&nbsp;</>
                    }
                    Type: <strong>{data.clothesType}</strong>
                </td>
                <td>PKR {data.Price}</td>
                <td>
                    <button onClick={() => { addQuantityonly(data) }} className={"btn btn-danger btn-sm themeBtn"}><i className="fas fa-plus"></i></button>
                    <span style={{ padding: "10px 20px" }}>{data.cartQuantity}</span>
                    <button onClick={() => { removeQuantityonly(data) }} className={"btn btn-danger btn-sm themeBtn"}><i className="fas fa-minus"></i></button>
                </td>
                <td>{data.cartQuantity * data.Price}</td>
                <td className={"align-content-center"}>
                    <button className={"btn btn-danger btn-sm themeBtn"} onClick={() => { deleteQuantityonly(data) }}>
                        <i className="fas fa-trash-alt"></i>
                    </button>
                </td>
            </tr>
        )
    })
    return (
        <>
            <div className={"container "}>
                <div className={"row"}>
                    <div className={"col-md-12 ptb80"} style={{ marginBottom: "150px" }}>
                        <h2 style={{ margin: "20px 0px" }}>Cart</h2>
                        <table className="table table-bordered table-striped table-hover showonDesktop">
                            <thead>
                                <tr>
                                    <th scope="col">Sr.No #</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Product Link</th>
                                    <th scope="col">Unit Price</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Total Price</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={6}><strong>Total Price</strong></td>
                                    <td><strong>PKR {sum}</strong></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className="showonmobile">
                            <div className="row " style={{ padding: "20px" }}>
                                {cart.map((data, index) => {
                                    return (
                                        <div className="col-md-12 itemCus">
                                            <div className="row">
                                                <div className="col-md-4 col-4">
                                                    <span>{index + 1} &nbsp;</span>
                                                    <img src={`${data.Thumbnail}`} width={"60px"} /> &nbsp;&nbsp;
                                                </div>
                                                <div className="col-md-8 col-8">
                                                    <span style={{ fontSize: "15px" }}>
                                                        Color: <span style={{ backgroundColor: data.color, borderRadius: "70px", padding: '5px 14px', marginLeft: "5px" }} ></span> &nbsp;
                                                        {data.clothesType === "Stiched" ?
                                                            <>Size: <strong>{data.size}</strong> &nbsp;</>
                                                            :
                                                            <>&nbsp;</>
                                                        }
                                                        Type: <strong>{data.clothesType}</strong>
                                                    </span> <br />
                                                    <div style={{ fontSize: "15px", paddingTop: "10px" }}>
                                                        <div style={{ marginBottom: "10px" }}>
                                                            <strong >PKR {data.Price}</strong> &nbsp;
                                                        </div>
                                                        <button onClick={() => { addQuantityonly(data) }} className={"btn btn-danger btn-sm themeBtn"}><i className="fas fa-plus"></i></button>
                                                        <span style={{ padding: "10px 20px" }}>{data.cartQuantity}</span>
                                                        <button onClick={() => { removeQuantityonly(data) }} className={"btn btn-danger btn-sm themeBtn"}><i className="fas fa-minus"></i></button>
                                                        <br />
                                                        <div style={{ marginTop: "10px" }}>
                                                            <strong >PKR {data.cartQuantity * data.Price}</strong>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                                <div className="col-md-12 itemCus">
                                    <div className="row">
                                        <div className="col-md-12 col-12" style={{textAlign:"right"}}>
                                            <span><strong>Total Price</strong> &nbsp;</span>
                                            {sum}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-12"} style={{ textAlign: "right" }}>
                                <Link to={To_home} className={"btn btn-danger themeBtn"} style={{marginBottom:"10px"}}>Continue Shopping &nbsp;  <i className="fas fa-shopping-cart"></i></Link> &nbsp; &nbsp; &nbsp;
                                {UserCheck === null &&
                                    <Link to={To_Login} className={"btn btn-danger themeBtn"} style={{marginBottom:"10px"}}>Login First To Checkout  &nbsp;<i className="fas fa-shopping-bag"></i></Link>
                                }
                                {UserCheck !== null &&
                                    <Link to={To_Checkout} className={"btn btn-danger themeBtn"} style={{marginBottom:"10px"}}>Checkout  &nbsp;<i className="fas fa-shopping-bag"></i></Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Cart