import React, { useState,useEffect } from "react";
import Slider1 from "../assets/images/sl1.jpg"
import Slider2 from "../assets/images/sl2.webp"
import readyTOware from "../assets/images/ReadyToWEAR.jpg"
import Unstiched from "../assets/images/Unstiched.jpg"
import Logo from "../assets/images/Humna & Momina.png"
import {To_Product_Details, To_Product_List} from "../helpers/Constants";

import Carousel from 'react-bootstrap/Carousel';
import AboutImg from "../assets/images/about_us.png"
import CategoryList from "../components/CategoryList";
import 'react-multi-carousel/lib/styles.css';
import ProductCard from "../components/ProductCard";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
const Home = () => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    useEffect(() => {
        // Scroll to the top when the component mounts or updates
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home | Hamna & Momina</title>
            </Helmet>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-md-12 p-0"}>
                        <Carousel activeIndex={index} onSelect={handleSelect}>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100 slidercus"
                                    src={Slider1}
                                    alt="First slide"
                                />
                                <Carousel.Caption className="showonDesktop">
                                    <h2>Humna & Momina</h2> <br/>
                                    <h2 className={"h22"}>
                                        <Link to={`ProductList?type=NewArrivals`}>
                                            Shop Now
                                        </Link>
                                    </h2>
                                    <br/>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100 slidercus"
                                    src={Slider2}
                                    alt="Second slide"
                                />

                                <Carousel.Caption className="showonDesktop">
                                    <h2>Humna & Momina</h2> <br/>
                                    <h2 className={"h22"}>
                                        <Link to={`ProductList?type=NewArrivals`}>
                                            Shop Now
                                        </Link>
                                    </h2>
                                    <br/>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>

            <div className={"container-fluid container-custom ptb80"}>
                <div className={"row"}>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 procard mb-3" >
                        <div className="card-flyer">
                            <div className="text-box">
                                <div class="geeks">
                                    <img src={readyTOware} alt="" style={{ width: "100%" }} />
                                    <div class="centered1">
                                        <Link className="cutomBBT1" to={`${To_Product_List}?type=ReadyToWhere`}>Ready To Wear</Link>
                                    </div>
                                    <div class="centered">
                                        <Link className="cutomBBT" to={`${To_Product_List}?type=ReadyToWhere`}>Shop Now</Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 procard mb-3">
                        <div className="card-flyer">
                            <div className="text-box">
                                <div class="geeks">
                                    <img src={Unstiched} alt="" style={{ width: "100%" }} />
                                    <div class="centered1">
                                        <Link className="cutomBBT1"  to={`${To_Product_List}?type=UNSTICHED`}>Unstiched</Link>
                                    </div>
                                    <div class="centered">
                                        <Link className="cutomBBT" to={`${To_Product_List}?type=UNSTICHED`}>Shop Now</Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>


            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className={"four"}>
                            <h1><span>HUMNA & MOMINA</span> Top <em>Selling</em> Products</h1>
                        </div>
                    </div>
                    <div className={"col-md-12"}>
                        <div className={"row"} id="cards_landscape_wrap-2">
                            <ProductCard />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <section className="parallax1">
                <div className={"container ptb80"}>
                    <div className={"row"}>
                        <div className={"col-md-12"} style={{textAlign:"center"}}>
                            <h2 className={"cheading showonDesktop"} style={{backgroundColor:"black",padding:"10px  30px"}}>Best Categories We Deal's With</h2>
                            <h2 className={"cheading showonmobile"} style={{backgroundColor:"black",padding:"10px  30px"}}>Categories</h2>
                        </div>
                        <div className={"col-md-12"}>
                            <CategoryList />
                        </div>
                    </div>
                </div>
            </section>

            <div className={"container ptb80"}>
                <div className={"row"}>
                    <div className={"col-md-12 text-center pb-5"}>
                        <div className="nine">
                            <h1>About US<span>HUMNA & MOMINA</span></h1>
                        </div>
                    </div>
                    <div className={"col-md-6 text-center"}>
                        <img src={Logo} width={"80%"} />
                    </div>
                    <div className={"col-md-6 text-center"}>
                        <p style={{ width: "80%", fontSize: "20px", marginTop: "20px",margin: "auto" }}>
                        HUMNA & MOMINA ( Bilal Textile) is a renowned fashion clothing brand with over 30 years of experience in the textile sector, producing fabric that is rich in quality and craftwork, HUMNA & MOMINA has successfully ventured into the retail sector by establishing itself as a high-end, fast fashion women’s wear brand.
                        </p>
                    </div>
                </div>
            </div>

            {/* <div className={"container ptb80"}>
                <div className={"row"}>
                    <div className={"col-md-12 text-center pb-5"}>
                        <div className="nine">
                            <h1>JOIN UMME ABDULLAH'S CLASSES<span>Umme Abdullah's Gluten Free</span></h1>
                        </div>
                    </div>
                    <div className={"col-md-6 text-center"}>
                        <img src={AboutImg} width={"80%"}/>
                    </div>
                    <div className={"col-md-6 text-center"}>
                        <p style={{width:"80%",fontSize:"20px",marginTop:"20px"}}>
                            Trust, honesty and integrity. These are the three principles our business is built on. By producing the very best in whole grain foods, we’re able to fulfill our commitment to help look after more and more people through better nutrition Umme Abdullah was developed to bring tasty, good for you breads and many more things to the market. Often people avoid bread or feel poorly after having a sandwich and this may be due to sensitivities to wheat or gluten But at Umme Abdullah it wasn’t enough to just create products that were just free from gluten and wheat, instead, we set out to develop a range of products that are free from all major allergens, that taste just as good as good as the normal conventional bread products AND have exceptional nutrition. Since using real, whole ingredients is the best way to bake, our products have an impressive list of high-quality, non-GMO ingredients. Whether it’s deriving protein and fiber from whole peas, apples and potatoes or using a unique blend of buckwheat and corn flours to provide gluten-like pliability, you’ll be impressed by our list of ingredients. Umme Abdullah’s products are not only high fiber and low fat, they’re also calorie-responsible.
                        </p>
                    </div>
                </div>
            </div> */}

        </>
    )
}
export default Home