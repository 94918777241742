import React,{useState,useEffect} from "react";
import ProductCard2 from "../components/ProductCardAll2";
import { Link, useParams } from "react-router-dom";
import {getAllCategoriescard, imageUrl} from "../controller/WebController";

const ProductListing2 = () => {
    const { type } = useParams();
    const [categories,setCategory] = useState([{}]);
    const [activeCategory,setActiveCategory] = useState("active")

    useEffect(() => {
        getAllCategoriesHome()
    }, []);

    console.log(type,"asdasdas")

    const getAllCategoriesHome = async () => {
        const res = await getAllCategoriescard();
        setCategory(res.categories)
    }


    return(
        <>
            <div className={"container-fluid ptb80 proList"}>
                <div className={"row"} style={{padding:"10px 40px"}}>
                    <div className={"col-md-12"}>
                        <div className={"four four222"}>
                            <h1 style={{borderTop:"1px solid #d0d0d0",borderBottom:"1px solid #d0d0d0",padding:"10px"}}><span>HUMNA & MOMINA</span> Top <em>Selling</em> Products</h1>
                        </div>
                    </div>
                    <div className="col-md-2 showonDesktop">
                        <div className={"four foura mt-5"}>
                            <h1 style={{textAlign:"left"}}><span></span><em>Categories</em></h1>
                        </div>
                        <ul className="catUl">
                            {categories.map((data,index)=>{
                                return(
                                    <li><Link  to={`../ProductList/${data.CategorySlug}`} className="proListCat">{data.CategoryName}</Link></li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className={"col-md-10"}>
                        <div className={"row"} id="cards_landscape_wrap-2">
                            <ProductCard2 type={type} />
                        </div>
                    </div>
                    <div className="col-md-2 showonmobile">
                        <div className={"four foura mt-5"}>
                            <h1 style={{textAlign:"left"}}><span></span><em>Categories</em></h1>
                        </div>
                        <ul className="catUl">
                            {categories.map((data,index)=>{
                                return(
                                    <li><Link  to={`../ProductList/${data.CategorySlug}`} className="proListCat">{data.CategoryName}</Link></li>
                                )
                            })}
                        </ul>
                    </div>
                    
                </div>
            </div>
        </>
    )
}
export default ProductListing2