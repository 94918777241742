import React, { useCallback, useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProductsDetail, imageUrl } from "../controller/WebController";
import Carousel from 'react-bootstrap/Carousel';
import { addQuantity, addToCart, deleteQuantity, removeQuantity, selectCart } from "../redux/cartSlice";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Accordion from 'react-bootstrap/Accordion';

const ProductDetail = () => {
    const { id } = useParams();
    const cart = useSelector(selectCart)
    const dispatch = useDispatch()
    const [pData, setPData] = useState({});
    const [clothesType, setClothesType] = useState("Unstiched");
    const [seletedColor, setSelectedColor] = useState("")
    const [sizesList, setSizesList] = useState(['XS', 'S', 'M', 'L', 'XL'])
    const [seletedSize, setSelectedSize] = useState("S")
    useEffect(() => {
        getProductDetails(id)
        window.scrollTo(0, 0);
    }, []);

    const getProductDetails = async (id) => {
        const res = await getProductsDetail(id);
        setPData(res.productDetails);
        let color = res.productDetails.colors[0]?.value;
        setSelectedColor(color)
        if (res.productDetails?.ProductPrice === "0") {
            setClothesType("Stiched")
        }

    }
    let filenamesArray = []
    pData?.images?.map((data, index) => {
        console.log(data)
        if (data.colorName === seletedColor) {
            filenamesArray = Object.keys(data?.imgCollection).map(key => ({
                original: imageUrl + "/products/" + data?.imgCollection[key],
                thumbnail: imageUrl + "/products/" + data?.imgCollection[key]
            }));


        }
    })
    const addToCard = async (productData) => {
        // 👇️ check if array contains object
        const isFound = cart.some(element => {
            console.log(element, "2312312312")
            if (clothesType === "Unstiched") {
                if (element.id === productData._id && element.color === seletedColor && element.clothesType === clothesType) {
                    return true;
                }
                return false;
            } else {
                if (element.id === productData._id && element.color === seletedColor && element.clothesType === clothesType && element.size === seletedSize) {
                    return true;
                }
                return false;
            }
        });
        if (isFound) {
            if (clothesType === "Unstiched") {
                const singleData = {
                    id: productData._id,
                    color: seletedColor,
                    clothesType: clothesType,
                    cartQuantity: 1
                }
                await dispatch(addQuantity(singleData))
            } else {
                const singleData = {
                    id: productData._id,
                    color: seletedColor,
                    clothesType: clothesType,
                    size: seletedSize,
                    cartQuantity: 1
                }
                await dispatch(addQuantity(singleData))
            }
        } else {
            if (clothesType === "Unstiched") {
                let price = ""
                if (productData.ProductDiscountPrice === "" || productData?.ProductDiscountPrice === "undefined") { price = productData.ProductPrice } else { price = productData.ProductDiscountPrice }
                const singleData = {
                    id: productData._id,
                    Name: productData.ProductName,
                    Slug: productData.ProductSlug,
                    Price: price,
                    color: seletedColor,
                    clothesType: clothesType,
                    Thumbnail: filenamesArray[0].thumbnail,
                    cartQuantity: 1,

                }
                // console.log(singleData)
                await dispatch(addToCart(singleData))
            } else {
                let price = ""
                if (productData.ProductDiscountStichedPrice === "" || productData?.ProductDiscountStichedPrice === "undefined") { price = productData.ProductStichedPrice } else { price = productData.ProductDiscountStichedPrice }
                const singleData = {
                    id: productData._id,
                    Name: productData.ProductName,
                    Slug: productData.ProductSlug,
                    Price: price,
                    color: seletedColor,
                    clothesType: clothesType,
                    size: seletedSize,
                    Thumbnail: filenamesArray[0].thumbnail,
                    cartQuantity: 1,

                }
                // console.log(singleData)
                await dispatch(addToCart(singleData))
            }

        }
        NotificationManager.success('Success message', 'Product Add To Cart Successfully');

    }

    const convertToPlain = (html) => {

        // Create a new div element
        const tempDivElement = document.createElement("div");

        // Set the HTML content with the given value
        tempDivElement.innerHTML = html;

        // Retrieve the text property of the element
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }




    const [col1, setCol1] = useState("btn-cus")
    const [col2, setCol2] = useState("btn-cusUnselect")
    const unStichedClothes = () => {
        setClothesType("Unstiched")
        setCol1('btn-cus')
        setCol2('btn-cusUnselect')
    }
    const stichedClothes = () => {
        setClothesType("Stiched")
        setCol1('btn-cusUnselect')
        setCol2('btn-cus')
    }

    const createMarkup = () => {
        return { __html: pData.ProductDescription };
    }


    const changeSize = (item) => {
        setSelectedSize(item)
    }

    const changeColor = (color) => {
        setSelectedColor(color)
        console.log(color)
    }
    const formatNumberWithCommas = (number) => {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    return (
        <>
            <div className={"container ptb80 prodetail"}>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <ImageGallery items={filenamesArray} autoPlay={true} />
                    </div>
                    <div className={"col-md-6"}>

                        <div className={"detailsection"}>
                            <div className={"row"}>

                                <div className={"col-md-12"}>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className={"col-md-12 pb-1"}>
                                                <span className="title">{pData.ProductName}</span>
                                                <br />
                                                <br />
                                                <h4 className="priceit">Price: </h4>
                                                {pData?.ProductPrice === "0" ?
                                                    <>
                                                        <span className="discount price"> <strong>PKR {formatNumberWithCommas(pData?.ProductStichedPrice)}</strong> </span>
                                                        <span className="price"> <strong>PKR {formatNumberWithCommas(pData?.ProductDiscountStichedPrice)}</strong> </span>
                                                    </>
                                                    :
                                                    <>
                                                        {clothesType === "Unstiched" ?
                                                            <>
                                                                {pData?.ProductDiscountPrice !== "" ?
                                                                    <>
                                                                        <span className="discount price"> <strong>PKR {formatNumberWithCommas(pData.ProductPrice)}</strong> </span>
                                                                        <span className="price"> <strong>PKR {formatNumberWithCommas(pData.ProductDiscountPrice)}</strong> </span>
                                                                    </>
                                                                    :
                                                                    <span className="price"> <strong>PKR {formatNumberWithCommas(pData.ProductPrice)}</strong> </span>
                                                                }
                                                            </> :
                                                            <>
                                                                {pData?.ProductDiscountStichedPrice !== "" ?
                                                                    <>
                                                                        <span className="discount price"> <strong>PKR {formatNumberWithCommas(pData.ProductStichedPrice)}</strong> </span>
                                                                        <span className="price"> <strong>PKR {formatNumberWithCommas(pData.ProductDiscountStichedPrice)}</strong> </span>
                                                                    </>
                                                                    :
                                                                    <span className="price"> <strong>PKR {formatNumberWithCommas(pData.ProductStichedPrice)}</strong> </span>
                                                                }
                                                            </>
                                                        }
                                                        {/* <>
                                                        <span className="discount price"> <strong>PKR {formatNumberWithCommas(pData?.ProductPrice)}</strong> </span>
                                                        <span className="price"> <strong>PKR {formatNumberWithCommas(pData?.ProductDiscountPrice)}</strong> </span>
                                                    </> */}
                                                    </>
                                                }

                                            </div>

                                        </div>
                                        <div className={"col-md-4"}>
                                            <button className={"btn btn-danger btn-cus"} onClick={() => { addToCard(pData) }}>
                                                Add To Cart <i className="fas fa-cart-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <hr />
                                    {pData?.sizes?.length === 0 ?
                                        <button className={`btn btn-danger ${col2}`} >Unstiched</button>
                                        :
                                        <>{pData?.ProductPrice === "0" ?
                                            <>
                                                <button className={`btn btn-danger ${col1}`} onClick={stichedClothes}>Stiched</button>
                                            </>
                                            :
                                            <>
                                                <button className={`btn btn-danger ${col1}`} onClick={unStichedClothes}>Unstiched</button> &nbsp;
                                                <button className={`btn btn-danger ${col2}`} onClick={stichedClothes}>Stiched</button>
                                            </>
                                        }


                                        </>
                                    }
                                    {clothesType === "Stiched" ?
                                        <>
                                            <div className="mt-3"></div>
                                            <span >Select Sizes</span>
                                            <div className="mt-2"></div>
                                            {sizesList.map((item, index) => {
                                                let col3 = "btn-cus";
                                                let col4 = "btn-cusUnselect";
                                                if (seletedSize === item) {
                                                    return (
                                                        <><button className={`btn btn-danger ${col3}`} >{item}</button> &nbsp;</>
                                                    )
                                                } else {
                                                    return (
                                                        <><button className={`btn btn-danger ${col4}`} onClick={() => { changeSize(item) }}>{item}</button> &nbsp;</>
                                                    )
                                                }
                                            })}
                                        </>
                                        :
                                        <></>

                                    }
                                    <hr />
                                    <span >Select Color :</span>
                                    <div className="mt-2"></div>
                                    {pData.colors?.map((data, index) => {
                                        if (seletedColor === data.value) {
                                            return (
                                                <span style={{ backgroundColor: data.value, borderRadius: "70px", border: "4px solid black", padding: '3px 14px', marginLeft: "5px" }} ></span>
                                            )
                                        } else {
                                            return (
                                                <span style={{ backgroundColor: data.value, borderRadius: "70px", padding: '3px 14px', marginLeft: "5px" }} onClick={() => { changeColor(data.value) }}></span>
                                            )
                                        }

                                    })}

                                </div>

                                <div className={"col-md-12 pt-4"}>
                                    <span style={{ fontWeight: "bolder", textDecoration: "underline", fontSize: "20px" }}>Description: </span>
                                    <p dangerouslySetInnerHTML={createMarkup()} className="mt-3">
                                        {/* {renderHTML(pData.ProductDescription)} */}
                                        {/* {
                                            convertToPlain(pData.ProductDescription)
                                        } */}
                                    </p>
                                </div>

                                <div className="col-md-12">
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><i class="fa-solid fa-truck"></i> &nbsp; DELIVERY</Accordion.Header>
                                            <Accordion.Body>
                                                DISPATCH TIME (PAKISTAN) : WITHIN ONE WEEK <br />
                                                DISPATCH TIME (INTERNATIONAL) : WITHIN 2 WEEKS
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header><i class="fa-solid fa-rotate-left"></i> &nbsp; RETURNS AND EXCHANGE</Accordion.Header>
                                            <Accordion.Body>
                                                ANY PRODUCT BOUGHT FROM Humna & Momina ONLINE STORE CAN BE EXCHANGED FROM THE ONLINE STORE ONLY, PROVIDED IT IS UNUSED AND STILL HAS THE LABEL AND TAG IN THEIR ORIGINAL STATE.<br/>
                                                FOR SALE ORDERS, EXCHANGES WILL BE PROVIDED FOR VALID REASONS ONLY I.E FAULTY OR INCORRECT ITEMS DELIVERED.<br/>
                                                REFUNDS ARE NOT IN CASH - THE CLIENT WILL BE ISSUED A COUPON OF THE SAME VALUE VALID FOR ONLINE STORE ONLY, WHICH CAN BE USED IMMEDIATELY OR IN THE FUTURE.<br/>
                                                EXCHANGE POLICY IS ONLY LIABLE WITHIN 14 DAYS AFTER THE GOODS ARE DELIVERED.<br/>
                                                FOR MORE INFORMATION REGARDING OUR EXCHANGE POLICY *CLICK HERE*<br/>
                                                FOR INTERNATIONAL ORDERS, WE DO NOT OFFER EXCHANGES UNLESS WE DELIVER THE INCORRECT ORDER OR THE PRODUCT IS FAULTY. * Humna & Momina DOES NOT PROVIDE EXCHANGE/REFUND FOR CUSTOM
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProductDetail