import React,{useEffect} from "react";
import AboutImg from "../assets/images/about_us.png";
import Logo from "../assets/images/Humna & Momina.png"

const Policy = () => {
    useEffect(() => {
        // Scroll to the top when the component mounts or updates
        window.scrollTo(0, 0);
    }, []);
    return(
        <>
            <div className={"container ptb80"}>
                <div className={"row"}>
                    <div className={"col-md-12 text-center pb-5"}>
                        <div className="nine">
                            <h1>Policy<span>HUMNA & MOMINA</span></h1>
                        </div>
                    </div>
                    <div className={"col-md-6 text-center"}>
                        <img src={Logo} width={"80%"} />
                    </div>
                    <div className={"col-md-6 text-center"}>
                        <p style={{ width: "80%", fontSize: "20px", marginTop: "20px",margin: "auto" }}>
                            This Privacy Policy describes how Humna & Momina (Bilal Textile)  (the "Site", "we", "us", or "our") collects, uses, and discloses your personal information when you visit, use our services, or make a purchase from humnaandmomina.com (the "Site") or otherwise communicate with us (collectively, the "Services"). For purposes of this Privacy Policy, "you" and "your" means you as the user of the Services, whether you are a customer, website visitor, or another individual whose information we have collected pursuant to this Privacy Policy.
                        </p>
                    </div>
                    <div className="col-md-12">
                        <h3 className="policyheading">CHANGES TO THIS PRIVACY POLICY</h3>
                        <p>
                        We may update this Privacy Policy from time to time, including to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will post the revised Privacy Policy on the Site, update the "Last updated" date and take any other steps required by applicable law.
                        </p>
                    </div>
                    <div className="col-md-12">
                        <h3 className="policyheading">HOW WE COLLECT AND USE YOUR PERSONAL INFORMATION</h3>
                        <p>
                            To provide the Services, we collect and have collected over the past 12 months personal information about you from a variety of sources, as set out below. The information that we collect and use varies depending on how you interact with us.
                            <br/>
                            In addition to the specific uses set out below, we may use information we collect about you to communicate with you, provide the Services, comply with any applicable legal obligations, enforce any applicable terms of service, and to protect or defend the Services, our rights, and the rights of our users or others.
                        </p>
                    </div>
                    <div className="col-md-12">
                        <h3 className="policyheading">COMPLAINTS</h3>
                        <p>
                            If you have complaints about how we process your personal information, please contact us using the contact details provided below. If you are not satisfied with our response to your complaint, depending on where you live you may have the right to appeal our decision by contacting us using the contact details set out below, or lodge your complaint with your local data protection authority.
                        </p>
                    </div>

                    <div className="col-md-12">
                        <h3 className="policyheading">INTERNATIONAL USERS</h3>
                        <p>
                            Please note that we may transfer, store and process your personal information outside the country you live in, including the United States. Your personal information is also processed by staff and third party service providers and partners in these countries.
                        </p>
                    </div>

                    <div className="col-md-12">
                        <h3 className="policyheading">CONTACT</h3>
                        <p>
                            Should you have any questions about our privacy practices or this Privacy Policy, or if you would like to exercise any of the rights available to you, please email us at info@humnaandmomina.com
                        </p>
                    </div>

                </div>
            </div>

        </>
    )
}
export default Policy