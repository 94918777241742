import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllProductsListing, imageUrl } from "../controller/WebController"
import { NotificationManager } from 'react-notifications';
import { useSelector, useDispatch } from 'react-redux'
import { addToCart, selectCart, addQuantity } from '../redux/cartSlice'
import { To_Product_Details, To_Product_List } from "../helpers/Constants";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

const ProductCard = (query) => {
    const dispatch = useDispatch()
    const [product, setProduct] = useState([]);
    const cart = useSelector(selectCart)
    const [nextPage, setNextPage] = useState({ page: "" });
    const [previousPage, setPreviousPage] = useState({ page: "" });
    const [count, setCount] = useState();
    const [currentPageOnly, setCurrentPage] = useState({ page: "1" });
    const [limit, setLimit] = useState(25);
    const [searchTerm, setSearchTerm] = useState('');
    const [count1, setCount1] = useState();
    let filters = query?.query

    useEffect(() => {
        search();
        window.scrollTo(0, 0);

    }, [filters]);


    console.log(filters)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            search();
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm]);
    const [lim, setLim] = useState("");
    const search = async () => {
        const res = await getAllProductsListing(currentPageOnly.page, limit, searchTerm, filters.type, filters.category , filters.fabric);
        setProduct(res.data.products.results);
        if(res.data.products.results.length === 0){
            setCount1(0)
        }else{
            setCount1(1)
        }
        setCount(res.data.count);
        if (res?.data.products.limit) {
            setLim(res.data.products.count);
        }
        if (res.data.products.next !== undefined) {
            setNextPage(res.data.products.next)
        } else {
            setNextPage(prevState => ({ ...prevState, page: "" }))
        }
        if (res.data.products.previous !== undefined) {
            setPreviousPage(res.data.products.previous)
        } else {
            setPreviousPage(prevState => ({ ...prevState, page: "" }))
        }
    }

    const nextPageMap = async (page) => {
        setCurrentPage({ page: page })
        const res = await getAllProductsListing(page, limit, searchTerm, filters.type, filters.category , filters.fabric);
        setCount(res.data.products.count)
        setProduct(res.data.products.results)
        if(res.data.products.results.length === 0){
            setCount1(0)
        }else{
            setCount1(1)
        }
        setNextPage({ page: "" })
        if (res.data.products.next !== undefined) {
            setNextPage(res.data.products.next)
        }
        if (res.data.products.previous !== undefined) {
            setPreviousPage(res.data.products.previous)
        }
    }
    const previousPageMap = async (page) => {
        setCurrentPage({ page: page })
        const res = await getAllProductsListing(page, limit, searchTerm, filters.type, filters.category , filters.fabric);
        setProduct(res.data.products.results)
        if(res.data.products.results.length === 0){
            setCount1(0)
        }else{
            setCount1(1)
        }
        setCount(res.data.products.count)
        setNextPage({ page: "" })
        if (res.data.products.next !== undefined) {
            setNextPage(res.data.products.next)
        }
        setPreviousPage({ page: "" })
        if (res.data.products.previous !== undefined) {
            setPreviousPage(res.data.products.previous)
        }
    };
    const currentPage = () => {
        if (nextPage.page !== "") {
            return (
                <li className="page-item"><button className="page-link linkActive">{nextPage.page - 1}</button></li>
            );
        }
        if (previousPage.page !== "") {
            return (
                <li className="page-item"><button className="page-link linkActive">{previousPage.page + 1}</button></li>
            )
        }
    };

    const InputHandler2 = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setLimit(value)
    }


    // const getProduct = async () => {
    //     const res = await getAllProductsListing(category);
    //     setProduct(res.products);
    // }
    const addToCard = async (productData) => {
        // 👇️ check if array contains object
        const isFound = cart.some(element => {
            if (element.id === productData._id) {
                return true;
            }
            return false;
        });

        if (isFound) {
            const singleData = {
                id: productData._id,
                cartQuantity: 1
            }
            await dispatch(addQuantity(singleData))
        } else {
            const singleData = {
                id: productData._id,
                Name: productData.ProductName,
                Slug: productData.ProductSlug,
                Price: productData.ProductPrice,
                Thumbnail: productData.ProductThumbnail,
                cartQuantity: 1
            }
            await dispatch(addToCart(singleData))
        }
        NotificationManager.success('Success message', 'Product Add To Cart Successfully');

    }
    const formatNumberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const list = [...product].reverse().map((pro, index) => {
        if(filters?.type === "ReadyToWhere"){
            if(pro?.sizes?.length !== 0){
                return (
                    <>
                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 procard">
                            <div className="card-flyer">
                                <div className="text-box">
                                    <div className="image-box">
                                        <Link to={`../` + To_Product_Details + "/" + pro?.ProductSlug}>
                                            <img src={`${imageUrl}/products/${pro?.images[0]?.imgCollection?.img1}`} alt="" />
                                        </Link>
                                    </div>
                                    <div className="text-container">
                                        <h6 >{pro.ProductName}</h6>
                                        {pro?.ProductPrice === "0" ?
                                            <>
                                                <span className="discount1 price1"> <strong>PKR {formatNumberWithCommas(pro?.ProductStichedPrice)}</strong> </span>
                                                <span className="price1"> <strong>PKR {formatNumberWithCommas(pro?.ProductDiscountStichedPrice)}</strong> </span>
                                            </>
                                            :
                                            <>
                                                <span className="discount1 price1"> <strong>PKR {formatNumberWithCommas(pro?.ProductPrice)}</strong> </span>
                                                <span className="price1"> <strong>PKR {formatNumberWithCommas(pro?.ProductDiscountPrice)}</strong> </span>
                                            </>
                                        }
                                        {/* <button className={"btn btn-danger btn-cus mt-1"} onClick={() => { addToCard(pro) }}>
                                            Add To Card <i className="fas fa-cart-plus"></i>
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        }else if(filters?.type === "UNSTICHED"){
            if(pro?.ProductPrice !== "0"){
                return (
                    <>
                        <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 procard">
                            <div className="card-flyer">
                                <div className="text-box">
                                    <div className="image-box">
                                        <Link to={`../` + To_Product_Details + "/" + pro?.ProductSlug}>
                                            <img src={`${imageUrl}/products/${pro?.images[0]?.imgCollection?.img1}`} alt="" />
                                        </Link>
                                    </div>
                                    <div className="text-container">
                                        <h6 >{pro?.ProductName}</h6>
                                        {pro?.ProductPrice === "0" ?
                                            <>
                                                <span className="discount1 price1"> <strong>PKR {formatNumberWithCommas(pro?.ProductStichedPrice)}</strong> </span>
                                                <span className="price1"> <strong>PKR {formatNumberWithCommas(pro?.ProductDiscountStichedPrice)}</strong> </span>
                                            </>
                                            :
                                            <>
                                                <span className="discount1 price1"> <strong>PKR {formatNumberWithCommas(pro?.ProductPrice)}</strong> </span>
                                                <span className="price1"> <strong>PKR {formatNumberWithCommas(pro?.ProductDiscountPrice)}</strong> </span>
                                            </>
                                        }
                                        {/* <button className={"btn btn-danger btn-cus mt-1"} onClick={() => { addToCard(pro) }}>
                                            Add To Card <i className="fas fa-cart-plus"></i>
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        }else{
            return (
                <>
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 procard">
                        <div className="card-flyer">
                            <div className="text-box">
                                <div className="image-box">
                                    <Link to={`../` + To_Product_Details + "/" + pro.ProductSlug}>
                                        <img src={`${imageUrl}/products/${pro?.images[0]?.imgCollection?.img1}`} alt="" />
                                    </Link>
                                </div>
                                <div className="text-container">
                                    <h6 >{pro.ProductName}</h6>
                                    {pro?.ProductPrice === "0" ?
                                        <>
                                            <span className="discount1 price1"> <strong>PKR {formatNumberWithCommas(pro?.ProductStichedPrice)}</strong> </span>
                                            <span className="price1"> <strong>PKR {formatNumberWithCommas(pro?.ProductDiscountStichedPrice)}</strong> </span>
                                        </>
                                        :
                                        <>
                                            <span className="discount1 price1"> <strong>PKR {formatNumberWithCommas(pro?.ProductPrice)}</strong> </span>
                                            <span className="price1"> <strong>PKR {formatNumberWithCommas(pro?.ProductDiscountPrice)}</strong> </span>
                                        </>
                                    }
                                    {/* <button className={"btn btn-danger btn-cus mt-1"} onClick={() => { addToCard(pro) }}>
                                        Add To Card <i className="fas fa-cart-plus"></i>
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        
    })
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div style={{ width: "250px", float: "right", marginRight: "20px" }}>
                        <Form.Group className="">
                            <InputGroup className="">
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    name={"Search"}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <InputGroup.Text id="basic-addon2">
                                    <div>
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div style={{ width: "250px", float: "right", marginRight: "20px" }} className="media">
                        <span style={{ fontSize: "15px" }}>Show Recoards</span> &nbsp; &nbsp;
                        <Form.Select value={limit} name="limit" onChange={InputHandler2} required className="customSelect" style={{ width: "100px", display: "inline-block" }} >
                            <option value={limit} selected>{limit}</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="85">85</option>
                            <option value="100">100</option>
                        </Form.Select>
                    </div>
                </div>
            </div>

            {list}

            {count1 === 0 &&
            <>
                <div className="col-md-12 mt-5">
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <p style={{fontSize:"40px"}}>No Product Found {filters?.category} {filters?.fabric}</p>
                </div>
            </>
            }

            <div className={"col-md-12 mt-3"} >
                <nav aria-label="Page navigation example">
                    <ul className="pagination" style={{ float: "right" }}>
                        {count > limit &&
                            <li className="page-item"><a className="page-link" style={{ color: "black" }}>show {lim === "" ? limit : lim} out of {count}</a></li>
                        }
                        {previousPage.page !== "" &&
                            <>
                                <li className="page-item"><button onClick={() => { previousPageMap(previousPage.page) }} className="page-link" style={{ color: "black" }}>{previousPage.page}</button></li>
                            </>
                        }
                        {currentPage()}
                        {nextPage.page !== "" &&
                            <>
                                <li className="page-item"><button onClick={() => { nextPageMap(nextPage.page) }} className="page-link" style={{ color: "black" }}>{nextPage.page}</button></li>
                            </>
                        }
                    </ul>
                </nav>
            </div>
        </>
    )
}
export default ProductCard