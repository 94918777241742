import React,{useState,useEffect} from "react";
import ProductCardAll from "../components/ProductCardAll";
import { Link, useParams,useLocation } from "react-router-dom";
import {getAllFabrics, imageUrl} from "../controller/WebController";

const ProductListing = () => {
    const [categories,setCategory] = useState([]);
    const [activeCategory,setActiveCategory] = useState("active")

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const category = queryParams.get('category');
    const fabric = queryParams.get('fabric');

    useEffect(() => {
        getAllCategoriesHome()
    }, []);

    let queryFilters = {
        type:type,
        category:category,
        fabric:fabric,
    }

    console.log(type,category,"asdasdas")

    const getAllCategoriesHome = async () => {
        const res = await getAllFabrics();
        setCategory(res.fabric)
    }


    return(
        <>
            <div className={"container-fluid ptb80 proList"}>
                <div className={"row"} style={{padding:"10px 40px"}}>
                    <div className={"col-md-12"}>
                        <div className={"four four222"}>
                            <h1 style={{borderTop:"1px solid #d0d0d0",borderBottom:"1px solid #d0d0d0",padding:"10px"}}><span>HUMNA & MOMINA</span> Top <em>Selling</em> Products</h1>
                        </div>
                    </div>
                    <div className="col-md-2 showonDesktop">
                        <div className={"four foura mt-5"}>
                            <h1 style={{textAlign:"left"}}><span></span><em>Fabrics</em></h1>
                        </div>
                        <ul className="catUl">
                            {categories.map((data,index)=>{
                                return(
                                    <li><Link  to={`../ProductList?type=${type}&category=${category}&fabric=${data?.FabricSlug}`} className={category === data?.FabicSlug  ? "active proListCat" :  "inactive proListCat"}>{data.FabricName}</Link></li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className={"col-md-10"}>
                        <div className={"row"} id="cards_landscape_wrap-2">
                            <ProductCardAll query={queryFilters}/>
                        </div>
                    </div>

                    <div className="col-md-2 showonmobile">
                        <div className={"four foura mt-5"}>
                            <h1 style={{textAlign:"left"}}><span></span><em>Fabrics</em></h1>
                        </div>
                        <ul className="catUl">
                            {categories?.map((data,index)=>{
                                return(
                                    <li><Link  to={`../ProductList?type=${type}&category=${category}&fabric=${data?.FabricSlug}`} className={category === data?.FabicSlug  ? "active proListCat" :  "inactive proListCat"}>{data?.FabricName}</Link></li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProductListing