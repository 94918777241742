import React, { useState, useEffect } from "react";
import Logo from "../assets/images/Humna & Momina.png"
import { Link, useNavigate } from "react-router-dom";

import {
    To_About,
    To_cart, To_Categories,
    To_contact,
    To_home,
    To_Login,
    To_Order_History,
    To_Register
} from "../helpers/Constants";
import { useSelector } from 'react-redux'
import { selectCart } from "../redux/cartSlice";
import Dropdown from 'react-bootstrap/Dropdown';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { getAllCategoriescard, imageUrl } from "../controller/WebController";


const Header = (prop) => {
    const navigate = useNavigate();
    const [category, setCategory] = useState([{}]);
    const token = localStorage.getItem('token');
    const cart = useSelector(selectCart)
    const [seachtext, setSeachText] = useState("")

    const logout = () => {
        localStorage.removeItem('token')
        prop("")
    }

    useEffect(() => {
        getAllCategoriesHome();
    }, []);

    const getAllCategoriesHome = async () => {
        const res = await getAllCategoriescard();
        setCategory(res.categories)
    }
    const stichedCategoryNames = ["WINTER 2 PCS", "VELVET 2 PCS", "VELVET 3 PCS","KIDS", "BOTTOMS", "DUPATTAS","SHAWLS"];
    const stichedCategoryNames2 = ["KIDS", "BOTTOMS", "DUPATTAS","SHAWLS"];
    const stichedCategories = category.filter(category => category.type === "Stiched" && stichedCategoryNames.includes(category.CategoryName));
    const stichedCategoriesOUTER = category.filter(category => category.type === "Stiched" && stichedCategoryNames2.includes(category.CategoryName));
    const UnstichedCategories = category.filter(category => category.type === "Unstiched");

    const sedToProListPage = () => {
        console.log("dsdasd")
        navigate(`/ProductList/search/${seachtext}`)
    }

    const elementToChange = document.getElementById("offcanvasNavbar-expand-sm");
    const elementToChange1 = document.getElementsByClassName("offcanvas-backdrop");
    const elementToChange2 = document.getElementById("eeee");
    
    const toggleCSSProperties = ()=>{
        elementToChange.classList.remove('show');
        elementToChange1[0].classList.remove('show');
        console.log(elementToChange1)
        elementToChange2.style.overflow = "auto";
        // elementToChange.classList.add('offcanvas offcanvas-end');
        // if (elementToChange.style.backgroundColor === "blue") {
        //   elementToChange.style.backgroundColor = "red";
        //   elementToChange.style.color = "white";
        // } else {
        //   elementToChange.style.backgroundColor = "blue";
        //   elementToChange.style.color = "black";
        // }
      }

    return (
        <div style={{ boxShadow: " 0px 0px 22px -3px rgba(0,0,0,0.57)" }} className="he">
            <nav className="py-2 border-bottom topnav">
                <div className="container d-flex flex-wrap">
                    <ul className="nav me-auto">
                        <li className="nav-item">
                            <a href="#" className="nav-link link-light px-2 ">
                                <i className="fab fa-facebook"></i>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link link-light px-2">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link link-light px-2">
                                <i className="fab fa-instagram-square"></i>
                            </a>
                        </li>
                    </ul>
                    <ul className="nav">
                        {token !== null &&
                            <>
                                <li className="nav-item"><Link onClick={logout} className="nav-link link-light px-2"><strong>Logout</strong></Link></li>
                            </>
                        }
                        {token === null &&
                            <>
                                <li className="nav-item"><Link to={To_Login} className="nav-link link-light px-2"><strong>Login</strong></Link></li>
                                <li className="nav-item"><Link to={To_Register} className="nav-link link-light px-2"><strong>Register</strong></Link></li>
                            </>
                        }
                    </ul>
                </div>
            </nav>

            {/* <header className="py-3 border-bottom">
                <div className="container d-flex flex-wrap justify-content-center">
                    <Link to={To_home}
                        className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-dark text-decoration-none">
                        <img src={Logo} width={"83px"} />
                        <h3 className={"logoHeading"}>Umme Abdullah's <br /> Gluten Free Cuisine</h3>
                    </Link>
                    <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 navsec">
                        <li><Link to={To_home} className="nav-link px-2 link-dark">Home</Link></li>
                        <li><Link to={To_About} className="nav-link px-2 link-dark">About</Link></li>
                        <li><Link to={To_Categories} className="nav-link px-2 link-dark">Categories</Link></li>
                        <li><Link to={To_Product_List} className="nav-link px-2 link-dark">Product</Link></li>
                        <li><Link to={To_contact} className="nav-link px-2 link-dark">Contact</Link></li>
                        <li>
                            <Link to={To_cart} className="nav-link px-2 link-dark">
                                <i className="fas fa-shopping-cart"></i>
                                <span className='badge badge-warning' id='lblCartCount'> {cart.length} </span>
                            </Link>
                        </li>


                        {token !== null &&
                            <>
                                <li className="nav-item">
                                    <Dropdown className="nav-link link-light px-2">
                                        <Dropdown.Toggle className={"bttn btn-danger btn-sm themeBtn"}>
                                            <i className="fas fa-user"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                <Link to={To_Order_History} className="link-dark">Orders</Link>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            </>
                        }

                    </ul>
                    <form className="col-12 col-lg-auto mb-3 mb-lg-0">
                        <input type="search" className="form-control" placeholder="Search..." aria-label="Search" style={{ marginTop: "23px", marginLeft: "20px" }} />
                    </form>
                </div>
            </header> */}

            {['sm'].map((expand) => (
                <Navbar key={expand} collapseOnSelect expand={expand} className="bg-body-tertiary">
                    <Container>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Brand href="#">
                            <Link to={To_home}
                                className="d-flex align-items-center mb-lg-0 me-lg-auto text-dark text-decoration-none">
                                <img src={Logo} width={"200px"} />
                            </Link>
                        </Navbar.Brand>
                        <Nav.Link className="showonmobile" style={{float:"right"}}>
                            <Link to={To_cart} className="nav-link px-2 link-dark">
                                <i className="fas fa-shopping-cart"></i>
                                    <span className='badge badge-warning' id='lblCartCount'> {cart.length} </span>
                            </Link>
                        </Nav.Link>

                        
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    <Link to={To_home}
                                        className="d-flex align-items-center mb-lg-0 me-lg-auto text-dark text-decoration-none">
                                        <img src={Logo} width={"200px"} />
                                    </Link>
                                </Offcanvas.Title>
                            </Offcanvas.Header>

                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Link onClick={toggleCSSProperties}>
                                        <Link to={To_home} className="nav-link px-2 link-dark" >Home</Link>
                                    </Nav.Link>

                                    <Nav.Link onClick={toggleCSSProperties}>
                                        <Link to={To_About} className="nav-link px-2 link-dark">About</Link>
                                    </Nav.Link>

                                    <Nav.Link onClick={toggleCSSProperties}>
                                        <Link className="nav-link px-2 link-dark" to={`ProductList?type=NewArrivals`}>New Arrivals</Link>
                                    </Nav.Link>

                                    <Nav.Link >
                                        <NavDropdown
                                            title=" UNSCTICHED"
                                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                                        >
                                            {UnstichedCategories.map((data, index) => {
                                                return (
                                                    <>
                                                        <NavDropdown.Item onClick={toggleCSSProperties}><Link to={`ProductList?type=Unstiched&category=${data.CategorySlug}`}>{data.CategoryName} </Link></NavDropdown.Item>
                                                    </>
                                                )
                                            })}

                                        </NavDropdown>
                                    </Nav.Link>
                            
                                    <Nav.Link  className="showonDesktop" id="dd">
                                        <NavDropdown
                                            title=" STICHED"
                                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                                            className="showonDesktop"
                                        >
                                            {stichedCategories.map((data, index) => {
                                                return (
                                                    <>
                                                        <NavDropdown.Item onClick={toggleCSSProperties} className="showonDesktop"><Link className="showonDesktop" to={`ProductList?type=Unstiched&category=${data.CategorySlug}`}>{data.CategoryName} </Link></NavDropdown.Item>
                                                    </>
                                                )
                                            })}

                                        </NavDropdown>
                                    </Nav.Link>

                                    {stichedCategories.map((data, index) => {
                                        return (
                                            <>
                                            {data.CategoryName === "WINTER 2 PCS" || data.CategoryName === "VELVET 2 PCS"  || data.CategoryName === "VELVET 3 PCS"  ?
                                             <>
                                             <Nav.Link className="showonmobile">
                                                <NavDropdown
                                                    title={data.CategoryName}
                                                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                                                >
                                                    <NavDropdown.Item onClick={toggleCSSProperties}><Link to={`ProductList?type=Stiched&category=${data.CategorySlug}`}>Ready To Wear</Link></NavDropdown.Item>
                                                </NavDropdown>
                                            </Nav.Link>
                                             </>
                                             :
                                             <>
                                              <Nav.Link onClick={toggleCSSProperties} className="showonmobile">
                                                    <Link className="nav-link px-2 link-dark" to={`ProductList?type=Stiched&category=${data.CategorySlug}`}>{data.CategoryName}</Link>
                                                </Nav.Link>
                                             </>
                                            }
                                               
                                            </>
                                        )
                                    })}
                                    
                                    

                                    {/* stichedCategoriesOUTER

                                    <Nav.Link onClick={toggleCSSProperties}>
                                        <Link className="nav-link px-2 link-dark" to={`ProductList?type=NewArrivals&category=All`}>New Arrivals</Link>
                                    </Nav.Link> */}


                                    <Nav.Link onClick={toggleCSSProperties}>
                                        <Link to={To_contact} className="nav-link px-2 link-dark">Contact</Link>
                                    </Nav.Link>

                                    <Nav.Link  onClick={toggleCSSProperties}>
                                        <Link to={To_cart} className="nav-link px-2 link-dark">
                                            <i className="fas fa-shopping-cart"></i>
                                            <span className='badge badge-warning' id='lblCartCount'> {cart.length} </span>
                                        </Link>
                                    </Nav.Link>


                                    {token !== null &&
                                        <>
                                            <Nav.Link >
                                                <Dropdown className="nav-link link-light px-2">
                                                    <Dropdown.Toggle className={"bttn btn-danger btn-sm themeBtn themeBtn2 mei"}>
                                                        <i className="fas fa-user" style={{ color: "white" }}></i> &nbsp;
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item>
                                                            <Link to={To_Order_History} className="link-dark">Orders</Link>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Nav.Link>

                                            {/* <li className="nav-item">
                                                <Dropdown className="nav-link link-light px-2">
                                                    <Dropdown.Toggle className={"bttn btn-danger btn-sm themeBtn"}>
                                                        <i className="fas fa-user"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item>
                                                            <Link to={To_Order_History} className="link-dark">Orders</Link>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li> */}
                                        </>
                                    }




                                </Nav>
                                {/* <Form className="d-flex">
                                    <Form.Control
                                        type="search"
                                        placeholder="Search"
                                        className="me-2"
                                        aria-label="Search"
                                        onChange={(e)=>{setSeachText(e.target.value)}}
                                    />
                                    <Button variant="outline-success">Search</Button>
                                </Form> */}
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
            {/* <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#features">Features</Nav.Link>
                            <Nav.Link href="#pricing">Pricing</Nav.Link>
                            <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav>
                            <Nav.Link href="#deets">More deets</Nav.Link>
                            <Nav.Link eventKey={2} href="#memes">
                                Dank memes
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar> */}
        </div>
    )
}
export default Header