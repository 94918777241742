import { createSlice } from '@reduxjs/toolkit'

let localData = JSON.parse(localStorage.getItem("cart"))
let localCart = [];
if(localData === null){
    localCart = []
}else{
    localCart = localData
}
export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: localCart,
    },
    reducers: {
        addToCart : (state, action) =>{
            state.cart = [...state.cart, action.payload]
            localStorage.setItem("cart",JSON.stringify(state.cart))
        },
        addQuantity : (state, action) =>{
            const newState = state.cart.map(obj => {
                // 👇️ if id equals 2, update array values
                if(action.payload.clothesType === "Unstiched"){
                    if (obj.id === action.payload.id && obj.color === action.payload.color && obj.clothesType === action.payload.clothesType ) {
                        return {
                            ...obj,
                            cartQuantity: obj.cartQuantity + action.payload.cartQuantity,
                        };
                    }
                }else{
                    if (obj.id === action.payload.id && obj.color === action.payload.color && obj.size === action.payload.size) {
                        return {
                            ...obj,
                            cartQuantity: obj.cartQuantity + action.payload.cartQuantity,
                        };
                    }
                }
                
                return obj;
            });
            state.cart = newState
            localStorage.setItem("cart",JSON.stringify(state.cart))
        },
        removeQuantity : (state, action) =>{
            const newState = state.cart.map(obj => {
                // 👇️ if id equals 2, update array values
                if(action.payload.clothesType === "Unstiched"){
                    if (obj.id === action.payload.id && obj.color === action.payload.color && obj.clothesType === action.payload.clothesType ) {
                        return {
                            ...obj,
                            cartQuantity: obj.cartQuantity - action.payload.cartQuantity,
                        };
                    }
                }else{
                    if (obj.id === action.payload.id && obj.color === action.payload.color && obj.size === action.payload.size) {
                        return {
                            ...obj,
                            cartQuantity: obj.cartQuantity - action.payload.cartQuantity,
                        };
                    }
                }
                return obj;
            });
            console.log(newState,"qweweq")
            state.cart = newState
            localStorage.setItem("cart",JSON.stringify(state.cart))
        },
        deleteQuantity : (state, action) => {

            // let singleItem = state.cart.filter(el => {
            //     console.log(action.payload,el.id )
            //     return el.id !== action.payload.id ;
            // });
            let filteredArray = []
            if (action.payload.clothesType === "Unstiched") {
                filteredArray = state.cart.filter(item => (
                    !(item.id === action.payload.id &&
                    item.color === action.payload.color &&
                    item.clothesType === action.payload.clothesType)
                ));
            }else{
                filteredArray = state.cart.filter(item => (
                    !(item.id === action.payload.id &&
                    item.size === action.payload.size &&
                    item.color === action.payload.color &&
                    item.clothesType === action.payload.clothesType)
                ));
            }
             
            console.log(filteredArray,"wdadasdas")
            state.cart = filteredArray
            localStorage.setItem("cart",JSON.stringify(state.cart))
        },
        emptyCart : (state, action) => {
            state.cart = []
            localStorage.removeItem("cart")
        },
    },
})

export const { addToCart,addQuantity,removeQuantity, deleteQuantity,emptyCart } = cartSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCart = (state) => state.cart.cart

export default cartSlice.reducer
