import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/Humna & Momina.png";
import {To_Product_Details, To_Product_List} from "../helpers/Constants";

import {
    To_About,
    To_cart, To_Categories,
    To_contact,
    To_home,
    To_Login,
    To_Order_History,
    To_policy,
    To_Register
} from "../helpers/Constants";
const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <img src={Logo} width={"200px"} />
                            <p>
                            HUMNA & MOMINA has successfully ventured into the retail sector by establishing itself as a high-end, fast fashion women’s wear brand.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h4 className="h4White" style={{color:"white",paddingLeft:"20px"}}>Links</h4>
                            <ul className="footerUl">
                                <li><Link className="menu__link" to={To_home}>Home</Link></li>
                                <li><Link className="menu__link" to={To_About}>About</Link></li>
                                <li><Link className="menu__link" to={To_policy}>Policy</Link></li>
                                <li><Link className="menu__link" to={To_contact}>Contact</Link></li>
                            </ul>
                            <ul className="footerUl">
                                <li><Link className="menu__link" to={`ProductList?type=NewArrivals`}>New Arrivals</Link></li>
                                <li><Link className="menu__link" to={`${To_Product_List}?type=UNSTICHED`}>Unstiched</Link></li>
                                <li><Link className="menu__link" to={`${To_Product_List}?type=ReadyToWhere`}>Ready To Wear</Link></li>
                                <li><Link className="menu__link" to={To_Login}>Login</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <h4 className="h4White" style={{color:"white",paddingLeft:"20px"}}>Contat</h4>
                            <ul className="footerUl">
                                <li><a href="mailto:info@humnaandmomina.com" className="menu__link" >Email: info@humnaandmomina.com</a></li>
                                <li><a href='tel:+92 323 4355442' className="menu__link">Phone: +92 323 4355442</a></li>
                                <li><a href='https://wa.me/+923234355442?text=How We Can Help You' className="menu__link">What's App: +92 323 4355442</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ul className="social-icon">
                    <li className="social-icon__item"><a className="social-icon__link" href="#">
                        <ion-icon name="logo-facebook"></ion-icon>
                    </a></li>
                    <li className="social-icon__item"><a className="social-icon__link" href="#">
                        <ion-icon name="logo-twitter"></ion-icon>
                    </a></li>
                    <li className="social-icon__item"><a className="social-icon__link" href="#">
                        <ion-icon name="logo-linkedin"></ion-icon>
                    </a></li>
                    <li className="social-icon__item"><a className="social-icon__link" href="#">
                        <ion-icon name="logo-instagram"></ion-icon>
                    </a></li>
                </ul>
                <p>&copy;2023 Humna & Momina | All Rights Reserved Developed By <a href="https://xampra.com" style={{ color: "white" }} target="_blank">XAMPRA</a></p>
            </footer>
        </>
    )
}
export default Footer